import React from 'react';
import { Link } from 'gatsby';

import { SHOW_UID_TO_NAME } from '../utils/constants';
import type { PrismicSeason } from '../types';
import { getFormattedDate } from '../utils/get-formatted-date';

type SeasonProps = {
  performanceData: PrismicSeason;
  displayShowName?: boolean;
  displayTicketLink?: boolean;
};

const Season = (props: SeasonProps) => {
  const {
    displayShowName = true,
    displayTicketLink = true,
    performanceData,
  } = props;
  const { show, start_date, showDate, end_date, ticket_link, venue, location } =
    performanceData.data;
  const ticketLink = ticket_link.url;

  return (
    <li className="dates__item">
      {displayShowName && (
        <div className="dates__show">
          <Link to={`/shows/${show}`}>{SHOW_UID_TO_NAME[show]}</Link>
        </div>
      )}
      {showDate !== false && (
        <div className="dates__date">
          {getFormattedDate(start_date, end_date)}
        </div>
      )}
      <div className="dates__location">
        {venue.text && (
          <>
            {ticketLink && displayTicketLink ? (
              <a
                href={ticketLink}
                target="_blank"
                rel="noopener noreferrer"
                className="dates__ticket-link"
              >
                {venue.text}
              </a>
            ) : (
              <span>{venue.text}</span>
            )}
            {' - '}
          </>
        )}

        {location.text}
      </div>
    </li>
  );
};

export default Season;
